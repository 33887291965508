import React, {useState} from 'react';
import Styled from 'styled-components/macro';

const textColor = 'rgba(97,218,251,255)'

const backgroundColor = 'rgba(34,34,34,255)'

const ButtonStyle = Styled.div`
    border-radius: 30px;
    background-color: ${backgroundColor};
    height: 60px;

    width: ${props => (props.expanded ? '400px' : '30px')};
    height: ${props => (props.expanded ? '200px' : '30px')};
    text-align: center;
    vertical-align: middle;
    transition: width 1000ms, height 1000ms;
    overflow: hidden;
    border: solid 1px ${textColor};
    position: fixed;
    z-index: 10;

    left: 360px;
    top: 20px;
`

const ButtonTextStyle = Styled.div `
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    overflow: hidden;
    width: ${props => (props.expanded ? '350px' : '15px')};
    height: ${props => (props.expanded ? '150px' : '25px')};
    text-align: center; 
    color: ${textColor}
  `

function InfoButton() {
    const [expand, setExpand] = useState(false)

    return (
        <ButtonStyle expanded = {expand} onMouseEnter={() => setExpand(true)} onMouseLeave={() => setExpand(false)}>
            <ButtonTextStyle expanded = {expand}>{(expand ? 'This is a website that agregates videos of games played in different langauges. When I was trying to learn French, I struggled to find videos of people playing my favorite game (Civ V) in French. So I decided to make the website you see here!' : 'i')}</ButtonTextStyle>
        </ButtonStyle>
    )
}

export default InfoButton