import React from 'react';
import DropdownSelector from './DropdownSelector.js';
import styled from 'styled-components/macro'
import { ConvertLanguageToIcon } from './Converters.js';
import './App.css'

const LanguageDescriptorStyle = styled.div`
    display: inline;
    vertical-align: middle;
    font-size: 15px;
`;

function LanguageSelectorDropdown({ languages, callback }) {
  const dropdownElements = languages.map((language) => (
    <>
      <img style={{ width: '18px'}} src={ConvertLanguageToIcon(language)} alt="Language Icon" />
      <LanguageDescriptorStyle >{language}</LanguageDescriptorStyle>
    </>
  ));

  return (
    <DropdownSelector
     style={{color: 'red'}}
      title="Language"
      //goddam this looks so bad. gotta fix the override issue when this drops "down"
      drop="up"
      elements={dropdownElements}
      callback={callback}
      callbackArguments={languages}
    />
  );
}

export default LanguageSelectorDropdown;
