import React from 'react';
import styled from 'styled-components/macro';

const DividerStyle = styled.div`
  opacity: 60%;
  color: #B1B1B1;
  font-size: ${props => props.dividerSize}px;
  font-kerning: none;
  width: 10px;
  text-align: center;
`;

function Divider({dividerSize}) {
  return <DividerStyle dividerSize={dividerSize}>|</DividerStyle>;
}

export default Divider;
