import React from "react";
import { VideoRecommendationContainer } from "./VideoRecommendation.js";
import Divider from "./Divider.js";
import styled from "styled-components/macro";

const HeaderDescriptors = styled.p`
  font-size: 13px;
  flex-basis: ${(props) => props.width}px;
  width: ${(props) => props.width}px;
  text-align: center;
`;

//these values should be handled by a wrapper that does this shit automatically but don't wanna convert it rn
const GameName_Width = 101;
const Channel_Width = 56;
const VideoImage_Width = 100;
const Captions_Width = 47;
const Platform_Width = 56;
const Language_Width = 56;

const DividerSize = 20;
function VideoRecommendationHeader() {
  return (
    <VideoRecommendationContainer verticalPadding={0} opacity={1}>
      <HeaderDescriptors width={GameName_Width}>Game Name</HeaderDescriptors>
      <Divider dividerSize={DividerSize} />
      <HeaderDescriptors width={Channel_Width}>Channel</HeaderDescriptors>
      <Divider dividerSize={DividerSize} />
      <HeaderDescriptors width={VideoImage_Width}>Video</HeaderDescriptors>
      <Divider dividerSize={DividerSize} />
      <HeaderDescriptors width={Captions_Width}>Captions</HeaderDescriptors>
      <Divider dividerSize={DividerSize} />
      <HeaderDescriptors width={Platform_Width}>Platform</HeaderDescriptors>
      <Divider dividerSize={DividerSize} />
      <HeaderDescriptors width={Language_Width}>Language</HeaderDescriptors>
    </VideoRecommendationContainer>
  );
}

export default VideoRecommendationHeader;
export {
  GameName_Width,
  Channel_Width,
  VideoImage_Width,
  Captions_Width,
  Platform_Width,
  Language_Width,
};
