import { VideoBackendData, GameGenreMap } from './BackendData'
import { GetVideoData, GetGenreData } from './DataLoading.js'
import ImportantGenres from './ImportantGenres.js'
// function MakeQuedsry(query) {
//     if (query === null) {
//         return [];
//     }
//     var results = VideoBackendData.filter(function (data) {
//         return data.language === query.language &&
//             (query.game === "" || data.gameName === query.game) &&
//             (query.genre === "" || data.genres.includes(query.genre))
//     });
//     console.log(results.length);
//     console.log(results[0].genre);
//     if (results.length !== 0) {
//         var gamesInSameGenre = Object.fromEntries(Object.entries(GameGenreMap).filter(([k, v]) => k !== query.game && v.some(r => results[0].genres.includes(r))));
//         console.log(gamesInSameGenre);
//     }
//     return results;
// }

//In the backend I called Mandarin chinese so have to map it here
const query_conversion_table = {
    "Mandarin": "Chinese"
}

function convert_query_language(language){
    return (language in query_conversion_table) ? query_conversion_table[language] : language
}

function MakeQuery(query) {
    if (query === null) {
        return [];
    }
    console.log(query);
    query.language = convert_query_language(query.language)
    if (query.game !== "") {
        return MakeGameQuery(query.game, query.language)
    }
    else if (query.genre !== "") {
        return MakeGenreQuery(query.genre, query.language)
    }
    else {
        console.log("No game or genre specified hmmm")
        return []
    }
}

function MakeGameQuery(game, language, cycle=true) {
    const GamesData = GetVideoData();
    if (game in GamesData) {
        const languageMatchingVideos = GamesData[game].filter(function (data) {
            return data.language === language
        })
        if (languageMatchingVideos.length !== 0){
            return languageMatchingVideos
        }
        else if(cycle) {
            console.log(`Didn't find game ${game} w/ language ${language}`)
            const GamesImportantGenres = GetGenreData()[game].filter(function (gamesGenre){
                return ImportantGenres.includes(gamesGenre);
            })
            console.log(GetGenreData()[game]);
            console.log(GamesImportantGenres)

            var matchingGamesFromGenres = []
            GamesImportantGenres.forEach(genre => matchingGamesFromGenres.push(...MakeGenreQuery(genre, language)));
            return matchingGamesFromGenres
        }
        else{
            console.log("Found no videos for " + game + " and told not to cycle")
        }
    }
    else{
        console.log(`Didn't find ${game} in games db. games list should source from there`)
    }
    return []
}

// Finds videos matching the genre listed in the language listed.
// returns list of video objs
function MakeGenreQuery(genre, language){
    const GenreData = GetGenreData()
    //Selects game-genre pairs that have the requested genre
    const MatchingGames = Object.fromEntries(Object.entries(GenreData).filter(([k,v]) => v.includes(genre)));
    console.log(MatchingGames)
    var matchingVideosFromGames = [] 
    for (const [game, gameGenres] of Object.entries(MatchingGames)) {
        matchingVideosFromGames.push(...MakeGameQuery(game, language, false))
      }
    return matchingVideosFromGames
}

export { MakeQuery };