import React from 'react';
import './App.css';
import VideoRecommendation from './VideoRecommendation';
import WelcomePage from './WelcomePage.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import {GetVideoData} from './DataLoading.js'
import InfoButton from './InfoButton';

export default function App() {
  return (
    <div>
      {/* Doesn't seem to have been updated in search results yet, maybe it needs a new crawl */}
      <title>Gaming for Language</title>
      <meta name="description" content="Find your favorite games played in your target language!"/>
      <div style={{backgroundColor: '#1B3755', height: '100vh', width: '100vw', position: 'fixed'}}/>
      <InfoButton/>
      
      <WelcomePage />
      
      {0 == 1 && (
        <div>
          <VideoRecommendation
            video_name="Civilization V"
            profile_image_link="https://raw.githubusercontent.com/Jonysegal/Language_Project/9a43f4c2b873dff02765fad9d55de6a47441fdb5/Cowee%20profile.jpg"
            video_image_link="https://raw.githubusercontent.com/Jonysegal/Language_Project/main/Cowee%20Vid%20Shot.png"
            captions="Some"
            platform="Youtube"
            language_image_link="https://raw.githubusercontent.com/Jonysegal/Language_Project/main/icons8-france-48.png"
          />
        </div>
      )}
    </div>
  );
}
