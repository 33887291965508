import React from "react";
import styled from "styled-components/macro";
import Divider from "./Divider.js";
import { ConvertPlatformToIcon, ConvertLanguageToIcon } from "./Converters.js";
import {
  GameName_Width,
  Channel_Width,
  VideoImage_Width,
  Captions_Width,
  Platform_Width,
  Language_Width,
} from "./VideoRecommendationHeader.js";
import PlayMedia from "./AssPeen.png";

const DescriptionText = styled.div`
  font-size: 18px;
  text-align: center;
`;

const VideoRecommendationInternalMargin = 10;

const VideoRecommendationContainer = styled.div`
  padding: ${(props) => props.verticalPadding}px 22px
    ${(props) => props.verticalPadding}px 22px;
  background-color: #eeeeee;
  display: flex;
  border-radius: 10px;
  align-items: center;
  > * {
    margin: 0 ${VideoRecommendationInternalMargin}px 0
      ${VideoRecommendationInternalMargin}px;
  }
  opacity: ${(props) => props.opacity};
  transition: opacity 1s;
`;
// //Select just children that conform to that. why $ not &? What is &
// ${DescriptionText} {
// }
const RoundedImage = styled.img`
  border-radius: 90px;
`;

const VideoImage = styled.img`
  width: 100px;
`;

const DividerSize = 40;

//Should probably have something that automatically adds dividers after children idk
function VideoRecommendation({
  video_name,
  profile_image_link,
  profile_link,
  video_image_link,
  video_link,
  captions,
  platform,
  language,
}) {
  const hasVideo = video_name !== "";
  console.log(hasVideo);
  console.log(video_name);
  //nothing should manipulate the object coming from backend until here. this component decides what it wants to render.
  return (
    <VideoRecommendationContainer
      verticalPadding={5}
      opacity={hasVideo ? 1 : 0}
    >
      <DescriptionText
        style={{
          width: GameName_Width,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {video_name}
      </DescriptionText>
      <Divider dividerSize={DividerSize} />
      <a href={profile_link}>
        <RoundedImage
          referrerPolicy="no-referrer"
          style={{ width: Channel_Width }}
          src={profile_image_link}
          alt="Creator Profile Image"
        />
      </a>
      <Divider dividerSize={DividerSize} />
      <a
        href={video_link}
        style={{ width: VideoImage_Width, position: "relative" }}
      >
        <img
          width="70px"
          src={PlayMedia}
          style={{
            position: "absolute",
            left: "16px",
            top: "19px",
            opacity: ".625",
          }}
        />
        <VideoImage
          src={video_image_link}
          alt="Video Image"
          style={{ display: "block" }}
        />
      </a>

      <Divider dividerSize={DividerSize} />

      <DescriptionText style={{ width: Captions_Width }}>
        {captions}
      </DescriptionText>
      <Divider dividerSize={DividerSize} />

      <RoundedImage
        style={{ width: Platform_Width }}
        src={ConvertPlatformToIcon(platform)}
        alt="Platform Image"
      />
      <Divider dividerSize={DividerSize} />
      <RoundedImage
        style={{ width: Language_Width }}
        src={ConvertLanguageToIcon(language)}
        alt="Lanugage Image"
      />
    </VideoRecommendationContainer>
  );
}

export default VideoRecommendation;
export { VideoRecommendationContainer };
