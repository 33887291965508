import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

function DropdownSelector({
  title,
  drop = "down",
  elements,
  callback,
  callbackArguments,
  enabled = true,
}) {
  const [renderedTitle, setRenderedTitle] = useState(title);
  if (!enabled && renderedTitle !== title) {
    setRenderedTitle(title);
  }
  //Could also putthis embedded below with curlies, gottem up here for readability
  const dropdownItems = elements.map((element, index) => (
    <Dropdown.Item
      style={{ padding: "0px 5px" }}
      key={index}
      onClick={() => {
        callback(callbackArguments[index]);
        setRenderedTitle(element);
      }}
    >
      {element}
    </Dropdown.Item>
  ));
  return (
    //the stupid override issue doesn't show up if everything points up kill me.
    // should really fix now but i don't wanna
    <Dropdown drop={drop}>
      <Dropdown.Toggle
        style={{
          opacity: "1",
          width: "160px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          backgroundColor: "#FFFFFF",
          outline: "none",
          boxShadow: "none",
          color: "black",
          borderStyle: "none",
        }}
        id="dropdown-basic"
      >
        {renderedTitle}
      </Dropdown.Toggle>

      <Dropdown.Menu
        id="dropdown-basic-button"
        title={title}
        style={{ maxHeight: "300px", overflowY: "scroll" }}
      >
        {dropdownItems}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownSelector;
