import React, { useState } from 'react'
// import styled from 'styled-components/macro'
import FirstPage from './FirstPage.js'
import { MakeQuery } from './Backend.js'
import SearchResults from './SearchResults.js'
// function VideoRecommendation({
//     video_name,
//     profile_image_link,
//     video_image_link,
//     captions,
//     platform,
//     language_image_link,
//   }) {
// constructor(gameName, profileLink, profileImageLink, videoLink, videoImageLink, captionInfo, platform, language, genres){
//aint got the actual links to video or profile working lol
function WelcomePage() {
    const [currentSearch, setCurrentSearch] = useState(null);
    //Maybe it shouldn't do this query every single time regarldesS? idk lol
    let searchResults = MakeQuery(currentSearch);

    const SearchButtonClicked = (searchData) => {
        setCurrentSearch(searchData);
    }
    //why are search results not rendering properly below?
    return (
        <>
            <FirstPage searchClickedCallback={SearchButtonClicked} />
            <SearchResults searchResults={searchResults}/>

        </>
    )
}

export default WelcomePage;