import React, { useState } from "react";
import styled from "styled-components/macro";
import DropdownSelector from "./DropdownSelector.js";
import LanguageSelectorDropdown from "./LanguageSelectorDropdown.js";
import { ConvertBoolToPointerEvent } from "./Converters";
import SearchData from "./SearchData.js";
import { GetVideoData } from "./DataLoading.js";
import ImportantGenres from "./ImportantGenres.js";
import { Link } from "react-scroll";

const BackgroundStyle = styled.div``;

const HorizontalCenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const BackgroundImageStyle = styled.img`
  position: relative;
  width: 1000px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const TitleStyle = styled.div`
  font-size: 30px;
  color: white;
  text-align: center;
  font-weight: bold;
`;

const DescriptionStyle = styled.div`
  font-size: 22px;
  color: white;
  text-align: center;
  font-weight: bold;
  max-width: 400px;
`;

const ContentStyle = styled.div`
  left: 50%;
  top: 240px;
  transform: translate(-50%, 0);
  position: absolute;
`;

const GameGenreDropdownsStyle = styled(HorizontalCenterWrapper)`
  > * {
    display: inline;
  }
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const SearchButtonStyle = styled.img`
  max-width: 60px;
  max-height: 60px;
`;

const ScalingWrapper = styled.div`
  transform: scale(${(props) => props.scale});
`;

const LanguageDropdownScale = "1.6";
const GameGenreDropdownScale = "1.15";

var gameList = [];

for (const [key, value] of Object.entries(GetVideoData())) {
  gameList.push(key);
}

const genreList = ImportantGenres;

function FirstPage({ searchClickedCallback }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedItems, setSelectedItems] = useState({ game: "", genre: "" });

  const LanguageDropdownChoiceSelected = (language) => {
    setSelectedLanguage(language);
  };

  const GameDropdownChoiceSelected = (game) => {
    setSelectedItems({ game: game, genre: "" });
  };

  const GenreDropdownChoiceSelected = (genre) => {
    setSelectedItems({ game: "", genre: genre });
  };

  const GameDropdownEnabled =
    selectedLanguage !== "" && selectedItems.genre === "";
  const GenreDropdownEnabled =
    selectedLanguage !== "" && selectedItems.game === "";
  const SearchIconEnabled =
    selectedLanguage !== "" &&
    (selectedItems.game !== "" || selectedItems.genre !== "");

  // #this is causing the opacity problem with dropdown over button
  // fix plz

  //
  const GameDropdownOpacity = GameDropdownEnabled || true ? 1 : 0.4;
  const GenreDropdownOpacity = GenreDropdownEnabled || true ? 1 : 0.4;
  const SearchIconOpacity = SearchIconEnabled ? 1 : 0.4;

  return (
    <BackgroundStyle>
      <BackgroundImageStyle src="https://raw.githubusercontent.com/Jonysegal/Language_Project/main/Clipped%20Gamer.png" />
      <ContentStyle>
        <TitleStyle>Gaming for Language</TitleStyle>

        <HorizontalCenterWrapper style={{ marginBottom: "20px" }}>
          <DescriptionStyle>
            Find your favorite games played in your target language
          </DescriptionStyle>
        </HorizontalCenterWrapper>

        <ScalingWrapper scale={LanguageDropdownScale}>
          <HorizontalCenterWrapper>
            <LanguageSelectorDropdown
              languages={[
                "French",
                "Spanish",
                "Mandarin",
                "Arabic",
                "Hindi",
                "German",
                "Russian",
                "Portuguese",
              ]}
              callback={LanguageDropdownChoiceSelected}
            />
          </HorizontalCenterWrapper>
        </ScalingWrapper>
        {/* Callbacks should change to callback and just have one function w the clicked thing as the argument */}
        <GameGenreDropdownsStyle>
          <ScalingWrapper
            scale={GameGenreDropdownScale}
            style={{
              zIndex: 10,
              opacity: GameDropdownOpacity,
              // pointerEvents: ConvertBoolToPointerEvent(GameDropdownEnabled),
            }}
          >
            <DropdownSelector
              title="Game"
              elements={gameList}
              callback={GameDropdownChoiceSelected}
              callbackArguments={gameList}
              enabled={GameDropdownEnabled}
            />
          </ScalingWrapper>
          <DescriptionStyle style={{ margin: "0 20px 0 20px" }}>
            or
          </DescriptionStyle>
          <ScalingWrapper
            scale={GameGenreDropdownScale}
            style={{
              zIndex: 10,
              opacity: GenreDropdownOpacity,
              // pointerEvents: ConvertBoolToPointerEvent(GenreDropdownEnabled),
            }}
          >
            <DropdownSelector
              title="Genre"
              elements={genreList}
              callback={GenreDropdownChoiceSelected}
              callbackArguments={genreList}
              enabled={GenreDropdownEnabled}
            />
          </ScalingWrapper>
        </GameGenreDropdownsStyle>
        {/* There must be a better way to do this other than wrapping everything in something else to wrap it horizontally */}
        <HorizontalCenterWrapper>
          <HorizontalCenterWrapper
            style={{
              opacity: SearchIconOpacity,
              background: "#0400B1",
              width: "81px",
              height: "81px",
              borderRadius: "90px",
              alignItems: "center",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "white",
            }}
          >
            {/* <Link style={{ pointerEvents: ConvertBoolToPointerEvent(SearchIconEnabled), cursor: 'pointer' }} > */}

            <SearchButtonStyle
              style={{
                pointerEvents: ConvertBoolToPointerEvent(SearchIconEnabled),
                cursor: "pointer",
              }}
              onClick={() => {
                searchClickedCallback(
                  new SearchData(
                    selectedLanguage,
                    selectedItems.game,
                    selectedItems.genre
                  )
                );
              }}
              src="https://raw.githubusercontent.com/Jonysegal/Language_Project/main/icons8-search-96.png"
            ></SearchButtonStyle>
            {/* </Link> */}
          </HorizontalCenterWrapper>
        </HorizontalCenterWrapper>
      </ContentStyle>
    </BackgroundStyle>
  );
}

export default FirstPage;
