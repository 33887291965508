import React from 'react'
import styled from 'styled-components/macro'
import {HorizontalCenterWrapper} from './BasicWrappers.js'
import VideoRecommendation from './VideoRecommendation.js'
import VideoRecommendationHeader from './VideoRecommendationHeader.js'
import {CircledGreyStyle} from './RecommendationStyles'
import {ConvertBoolToCaptions} from './Converters.js'
import {scroller} from 'react-scroll'

const ResultsWrapper = styled.div`
    position: relative;
    margin: 10px;
    > * {
        margin-bottom: 10px;
    }
`;

const HeaderResultsDivider = styled.hr`
    border: 1px solid #B1B1B1;
    margin: 0;
    //Have to set that explicitley because hr comes with a default margin of 1rem 0. maybe there's a more elegant way to remove it
    margin-bottom: 10px;
`
// Will have to change this again when add the idea of not just first result, you might also like, ec

function SearchResults({searchResults}){
    const firstResult = searchResults.length > 0 ? searchResults[0] : null;
    console.log(firstResult)
    if(searchResults.length !== 0){
        scroller.scrollTo("Search Results", {
            duration: 800,
            smooth: "easeInOutQuart"
          });
    }
    return(
        <>
        {searchResults.length === 0 ? null :
        (
            <HorizontalCenterWrapper>
            <ResultsWrapper>
            
            <VideoRecommendationHeader/>
            <HeaderResultsDivider/>
            <VideoRecommendation 
            // Sometimes i think this should be video name sometimes game name i waffle
            video_name={firstResult["game"]}
            video_link={firstResult["link"]}
            profile_image_link={firstResult["channel_image_link"]}
            profile_link={firstResult["channel_link"]}
            video_image_link={firstResult["thumbnail_link"]}
            captions={ConvertBoolToCaptions(firstResult["captions"])}
            platform='Youtube'
            language={firstResult['language']}/>

{/* another place where the searchresults won't just be an array in future so this is lazy for now */}

{/* this whole file will be very different, obvi same components but v different data source */}
            {searchResults.length > 1 ? (

            <HorizontalCenterWrapper>
            <CircledGreyStyle verticalPadding={0} style={{textAlign: 'center'}}>
                <em>You might also like...</em>
            </CircledGreyStyle>
            </HorizontalCenterWrapper>

            ) : null
}
        </ResultsWrapper>
        </HorizontalCenterWrapper>

        )
        }
        <div id={"Search Results"}/>

        </>
    )
}

export default SearchResults;