const PlatformImageTable = {
    Youtube:
      'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/youtube_icon.png',
    Twitch:
      'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/twitch_icon.png',
  };
  
  const LanguageImageTable = {
    Mandarin:
      'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-china-96.png',
    Hindi:
      'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-india-96.png',
    Arabic:
    'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-saudi-arabia-96.png',
    French:
    'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-france-96.png',
    Spanish:
    'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-spain-96.png',
    German:
    'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-germany-96.png',
    Russian:
    'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-russian-federation-96.png',
    Portuguese:
    'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/language%20project%20flags/icons8-portugal-96.png',
  };
  
  function ConvertLanguageToIcon(language) {
    return LanguageImageTable[language];
  }
  
  function ConvertPlatformToIcon(platform) {
    return PlatformImageTable[platform];
  }

function ConvertBoolToPointerEvent(enabled){
  return enabled ? 'all' : 'none';
}

function ConvertBoolToCaptions(captions){
  return captions ? 'Yes' : 'No';
}

    // var toType = function (obj) {
  //   return {}.toString
  //     .call(obj)
  //     .match(/\s([a-zA-Z]+)/)[1]
  //     .toLowerCase();
  // };
  export { ConvertLanguageToIcon, ConvertPlatformToIcon, ConvertBoolToPointerEvent, ConvertBoolToCaptions};
  