import VideoData from './VideoData.js'
//constructor(gameName, profileLink, profileImageLink, videoLink, videoImageLink, captionInfo, 
//platform, language, genres){
    
const VideoBackendData = [
    new VideoData('Civilization V', 'https://www.youtube.com/c/ChoweesGamingShow', 'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/Cowee%20profile.jpg', 'https://www.youtube.com/watch?v=5js5c4xyER8', 'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/Cowee%20Vid%20Shot.png', 'Some', 'Youtube', 'French', ['Strategy']),
    new VideoData('Civilization VI', 'https://www.youtube.com/c/AypierreFr', 'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/secondAccount.jpg', 'https://www.youtube.com/watch?v=Y4pQx65KTW0&t=2s&ab_channel=Aypierre', 'https://raw.githubusercontent.com/Jonysegal/Language_Project/main/Civ%20VI%20Vid.png', 'Yes', 'Youtube', 'French', ['Strategy'])
];

//just realized the genres thing up there would have redunent data as is in the table below. obvi this whole file is junk for future backend just a funny notice.

const GameGenreMap = {'Civilization V': ['Strategy', '4X'], 'Civilization VI': ['Strategy', '4X']};


export {VideoBackendData, GameGenreMap};