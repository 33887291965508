class VideoData {
    constructor(gameName, profileLink, profileImageLink, videoLink, videoImageLink, captionInfo, platform, language, genres){
        this.gameName = gameName;
        this.profileLink = profileLink;
        this.profileImageLink = profileImageLink;
        this.videoLink = videoLink;
        this.videoImageLink = videoImageLink;
        this.captionInfo = captionInfo;
        this.platform = platform;
        this.language = language;
        this.genres = genres;
    }
}

export default VideoData;